import React from 'react';
import Layout from '../components/layout';
import Content from '../components/content';

export default () => {
  return (
    <Layout>
      <Content><p>Shift Reset LLC Terms of Service</p>
<ol>
<li>Terms</li>
</ol>
<p>By accessing the website at <a href="https://actualbudget.com">https://actualbudget.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
<ol start="2">
<li><p>Use License</p>
<p>Permission is granted to temporarily download one copy of the materials (information or software) on Shift Reset LLC's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
<pre><code> modify or copy the materials;
 use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
 attempt to decompile or reverse engineer any software contained on Shift Reset LLC&#39;s website;
 remove any copyright or other proprietary notations from the materials; or
 transfer the materials to another person or &quot;mirror&quot; the materials on any other server.
</code></pre>
<p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Shift Reset LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p></li>
<li><p>Disclaimer</p>
<p>The materials on Shift Reset LLC's website are provided on an 'as is' basis. Shift Reset LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Shift Reset LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p></li>
<li><p>Limitations</p></li>
</ol>
<p>In no event shall Shift Reset LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Shift Reset LLC's website, even if Shift Reset LLC or a Shift Reset LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
<ol start="5">
<li>Accuracy of materials</li>
</ol>
<p>The materials appearing on Shift Reset LLC's website could include technical, typographical, or photographic errors. Shift Reset LLC does not warrant that any of the materials on its website are accurate, complete or current. Shift Reset LLC may make changes to the materials contained on its website at any time without notice. However Shift Reset LLC does not make any commitment to update the materials.</p>
<ol start="6">
<li>Links</li>
</ol>
<p>Shift Reset LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Shift Reset LLC of the site. Use of any such linked website is at the user's own risk.</p>
<ol start="7">
<li>Modifications</li>
</ol>
<p>Shift Reset LLC may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
<ol start="8">
<li>Governing Law</li>
</ol>
<p>These terms and conditions are governed by and construed in accordance with the laws of Virginia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p></Content>
    </Layout>
  );
};
